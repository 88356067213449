import React, { useState } from "react";
import { baseUrl } from "../App";

async function loginUser(credentials) {
  return fetch(`${baseUrl}admin/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = await loginUser({
      email,
      password,
    });

    setToken(token);
  };

  return (
    <div className="login_container">
      <div className="logo">
        <p>Login</p>
      </div>

      <div className="content">
        <form onSubmit={handleSubmit}>
          <div className="common_input_main_div" style={{ marginTop: 25 }}>
            <p className="common_input_lable">Email</p>
            <input
              name="email"
              className="common_input_field"
              type="email"
              placeholder="Enter your Email!"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="common_input_main_div" style={{ marginTop: 25 }}>
            <p className="common_input_lable">Password</p>
            <input
              name="password"
              className="common_input_field"
              type="password"
              placeholder="Enter your Password!"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="common_bttn"
            style={{ width: "100%", marginTop: 30 }}
          >
            <p className="common_bttn_p">Login</p>
          </button>
        </form>
      </div>
    </div>
  );
}
