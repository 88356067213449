import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import AppLayout from "./components/layout";
import useToken from "./components/useToken";
import Doctor from "./Pages/Doctor";
import Login from "./Pages/Login";
import Share from "./Pages/Share";
import Wishes from "./Pages/Wishes";

// export const baseUrl = "https://api.epilog.world/";
// export const baseUrl = "https://epilog.thecodingbuzz.com/";
export const baseUrl = "/";

export const PATHS = {
  LOGIN: "/login",
  DASHBOARD: "/",
  SHARE: "/shareview",
  WISHES: "/wishesview",
  DOCTOR: "/doctorview",
};
// export const baseUrl = "http://172.15.194.130:3000/";

function App() {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log(token, "asd");

    if (!token && location.pathname !== PATHS.LOGIN) {
      return navigate(PATHS.LOGIN);
    }

    if (token && location.pathname === PATHS.LOGIN) {
      return navigate(PATHS.DASHBOARD);
    }
  }, [token, navigate, location]);
  useEffect(() => {
    // window.addEventListener('loadItem',()=>{
    //   console.log('item')
    // })
  }, []);
  return (
    <Routes>
      <Route path={PATHS.LOGIN} element={<Login setToken={setToken} />} />
      <Route
        path={PATHS.DASHBOARD}
        element={<AppLayout navigate={navigate} setToken={setToken} />}
      >
        <Route path={PATHS.WISHES} element={<Wishes />} />
        <Route path={PATHS.SHARE} element={<Share />} />
        <Route path={PATHS.DOCTOR} element={<Doctor />} />
        <Route path="*" element={<Navigate to={PATHS.DASHBOARD} replace />} />
      </Route>
    </Routes>
  );
}

export default App;
