import { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../App";
import AppTable from "../components/table";
import useToken from "../components/useToken";

export default function Doctor() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { token, setToken } = useToken();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = useCallback(() => {
    setLoading(true);
    fetch(`${baseUrl}admin/Doctor-Table`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(({ data, success, error }) => {
        if (success) {
          setData(data);
        } else if (error?.expire) {
          setToken(null);
        }
        setLoading(false);
        // setTableParams({
        //   ...tableParams,
        //   pagination: {
        //     ...tableParams.pagination,
        //     total: 200,
        //   },
        // });
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  let newColumns = data?.columns?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        title: curr,
        dataIndex: curr.toLowerCase().replaceAll(" ", "_"),
        render: (value) => value ?? "N/A",
      },
    ];
  }, []);

  return (
    <AppTable
      filePath={data.path}
      title="Doctor Table"
      columns={newColumns}
      data={data?.data}
      loading={loading}
      tableParams={tableParams}
      setTableParams={setTableParams}
    />
  );
}
