import {
  DashboardOutlined,
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PATHS, baseUrl } from "../../App";
const { Content, Sider } = Layout;

function getItem(label, key, icon, onClick) {
  return {
    key,
    icon,
    label,
    onClick,
  };
}

const AppLayout = ({ navigate, setToken }) => {
  // const { setToken } = useToken();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const items = [
    getItem("Dashboard", "/", <DashboardOutlined />, () => {
      navigate(PATHS.DASHBOARD);
    }),
    getItem("Share Table", "/share", <PieChartOutlined />, () => {
      navigate(PATHS.SHARE);
    }),
    getItem("Wishes Table", "/wishes", <DesktopOutlined />, () => {
      navigate(PATHS.WISHES);
    }),
    getItem("Doctor Table", "/doctor", <FileOutlined />, () => {
      navigate(PATHS.DOCTOR);
    }),
  ];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        width="250px"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div>
          <img
            alt="logo"
            src={`${baseUrl}public/assets/images/logo.svg`}
            className="logo"
          />
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            items={items}
          />
        </div>
        <img
          src={`${baseUrl}public/assets/images/logout.svg`}
          alt="img"
          onClick={() => {
            setToken(null);
          }}
          className="logout-btn"
        />
      </Sider>
      <Layout className="site-layout">
        <Content>
          <div>
            <h2 className="title">Epilog Dashboard</h2>
            <p className="dtl">Welcome, admin</p>
          </div>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
