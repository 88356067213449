import { Button } from "antd";
import React from "react";
import styles from "./style.module.scss";

const AppButton = ({ title, onClick = () => null }) => {
  return (
    <Button onClick={onClick} className={styles.appBtn}>
      {title}
    </Button>
  );
};

export default AppButton;
