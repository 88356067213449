import { Row, Table } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { baseUrl } from "../../App";
import AppButton from "../button";

const ExpandableTable = ({ doctor }) => {
  let obj = {};
  if (doctor?.replies) {
    obj = doctor.replies.reduce((acc, val) => {
      const ques = val.question;
      if (ques) {
        const module = acc[ques.moduleId] ?? {
          questions: [],
          ...ques.module,
        };
        module.questions.push({ ...ques, answer: val.answer });
        acc[ques.moduleId] = module;
      }
      return acc;
    }, {});
  }

  const data = Object.values(obj);

  return data.map(({ name, questions = [] }, i) => {
    return (
      <div class="table-drop-down" key={i}>
        <p class="heading">{name}</p>
        <ol>
          {questions.map(({ question }, ind) => {
            if (question) {
              return <li key={ind}>{question}</li>;
            }
          })}
        </ol>
      </div>
    );
  });
};

const AppTable = ({
  title,
  filePath,
  columns,
  data,
  loading,
  tableParams,
  setTableParams,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // const handleTableChange = (pagination, filters, sorter) => {
  //   setTableParams({
  //     pagination,
  //     filters,
  //     ...sorter,
  //   });
  // };

  /*--------------------*/
  /*       Search       */
  /*--------------------*/

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const modifyData = useCallback(() => {
    return data?.reduce((acc, curr, key) => {
      let { user, basicInfo, moreInfo, doctor, ...rest } = curr;
      let { deviceId: deviceid, ...restUser } = user ?? {};
      let {
        wake_up: wakeup,
        friends_Family: friends_family,
        ...restMore
      } = moreInfo ?? {};
      let { replies, ...restDoctor } = doctor ?? {};

      return [
        ...acc,
        {
          key,
          ...(!!curr.user ? { deviceid, ...restUser } : {}),
          ...(!!curr.moreInfo ? { wakeup, friends_family, ...restMore } : {}),
          ...(!!curr.basicInfo ? { ...basicInfo } : {}),
          ...(!!curr.doctor ? { ...restDoctor, doctor: { replies } } : {}),
          ...rest,
        },
      ];
    }, []);
  }, [data]);

  let customData = modifyData();

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: "block",
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({
  //               closeDropdown: false,
  //             });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? "#1890ff" : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: "#ffc069",
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  /*--------------------*/
  /*       Search       */
  /*--------------------*/

  function downloadFile() {
    const a = document.createElement("a");
    a.href = baseUrl + "uploads/" + filePath;
    a.download = baseUrl + filePath.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <div className="tbl">
      <Row className="tbl-row">
        <p>{title}</p>
        <AppButton title="Export to excel" onClick={downloadFile} />
      </Row>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        expandable={{
          expandedRowRender: (record) => <ExpandableTable {...record} />,
          rowExpandable: ({ doctor }) => !!(doctor?.replies || []).length,
        }}
        dataSource={customData}
        // pagination={tableParams.pagination}
        // onChange={handleTableChange}
        loading={loading}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};

export default AppTable;
